import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';

import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import AppRoutes from './routes';
import { history, store } from './store/createStore';

import { muiTheme } from './styles/muiTheme';

import './styles/index.css';

function App() {
  return (
    <>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <AppRoutes />
    </>
  );
}

function AppContainer() {
  const theme = React.useMemo(() => {
    return createTheme(muiTheme);
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <HistoryRouter history={history}>
            <App />
          </HistoryRouter>
        </ReduxProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default AppContainer;
